import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Container, Typography, Grow } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    width: "100%",
    height: 60,
    background: theme.palette.secondary.light,
    "&:hover": {
      background: theme.palette.secondary.light,
      transform: "scale(1.1,1.2)",
      transition: "0.3s"
    }
  },
  text: {
    color: theme.palette.text.primary.light,
    fontWeight: "bold"
  }
}));

const ButtonChoices = props => {
  let { data, onChoice } = props;
  const classes = useStyles();
  return (
    <Grow
      in={true}
      style={{ transformOrigin: "50 50 50" }}
      {...(true ? { timeout: 2000 } : {})}
    >
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              justify="center"
              spacing={4}
              className={classes.root}
            >
              {data.choices.map((value, idx) => (
                <Grid key={idx} item xs={12}>
                  <Button
                    variant="contained"
                    focusRipple
                    onClick={onChoice(value.text)}
                    className={classes.button}
                  >
                    <Typography variant="h5" className={classes.text}>
                      {value.text}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grow>
  );
};
export default ButtonChoices;
