import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { ContentfulClient, ContentfulProvider } from "react-contentful";
import Survey from "./components/Survey";
import SiteHeader from "./components/SiteHeader";
// THEME
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import muiTheme from "./muiTheme";

const contentfulClient = new ContentfulClient({
  accessToken: "7Wj1Qol3wiRv4ZvPlIb1pZRbKKMzDLY-XGGuc_lUu9c",
  space: "cdyxtpbavd4i"
});


function App() {
  return (
    <ContentfulProvider client={contentfulClient}>
      <Router>
        <MuiThemeProvider theme={muiTheme}>
          <React.Fragment>
            <CssBaseline />
            <SiteHeader />
            <Route path="/:survey_id?" component={Survey} />
          </React.Fragment>
        </MuiThemeProvider>
      </Router>
    </ContentfulProvider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);


