import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import RoundChoices from "./RoundChoices";
import ButtonChoices from "./ButtonChoices";

const useStyles = makeStyles(theme => ({
  question: {
    fontWeight: "bold"
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 400
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const SurveySection = props => {
  let classes = useStyles();
  let { data, onChoice } = props;
  const SurveyChoices = props => {
    if (data.type === "buttonchoice") {
      return <ButtonChoices {...props} />;
    }
    else {
      return <RoundChoices {...props} />;
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="md" style={{ padding: 30 }}>
        <Typography
          align="center"
          color="secondary.light"
          variant="h4"
          className={classes.question}
        >
          {data.question}
        </Typography>
      </Container>
      <SurveyChoices data={data} onChoice={onChoice} />
    </React.Fragment>
  );
};
export default SurveySection;
