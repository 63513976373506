import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Typography } from "@material-ui/core";
import qs from "query-string";
import sendChugEvent from '../utils/sendChugEvent';
import Countdown from './Countdown';

const useStyles = makeStyles(theme => ({
  button: {
    width: "80%",
    height: 60,
    color: theme.palette.text.light,
    background: theme.palette.secondary.light,
    "&:hover, &$focusVisible": {
      background: theme.palette.secondary.light,
      transform: "scale(1.1,1.2)",
      transition: "0.3s"
    }
  }
}));


const Complete = props => {
  let classes = useStyles();
  let { data, location, surveyId } = props;

  React.useEffect(() => {
    sendChugEvent('completed', { 'survey_id': surveyId });
  }, [surveyId]);

  const buildLink = () => {
    const args = qs.parse(location.search);
    let link = data.button.link;
    if (args.ad) {
      link += `&ad=${args.ad}`;
    }
    if (args.acampaign) {
      link += `&acampaign=${args.acampaign}`;
    }
    if (args.gsub_id) {
      link += `&gsub_id=${args.gsub_id}`;
    }
    if (args.gallery_id) {
      link += `&gallery_id=${args.gallery_id}`;
    }
    return link;
  };

  const onButtonClicked = async() => {
    let link = buildLink();
    await sendChugEvent('clicked', { 'survey_id': surveyId, url: link });
    window.location.href = link;
  }

  return (
    <React.Fragment>
      <Container maxWidth="md" style={{ padding: 10 }}>
        <Typography
          align="center"
          color="secondary.light"
          variant="h6"
        >
          {data.text[0]}
        </Typography>
      </Container>
      <Container maxWidth="md" style={{ padding: 10 }}>
        <Typography
          align="center"
          color="secondary.light"
          variant="h5"
        >
          {data.text[1]}
        </Typography>
      </Container>
      {data.text[2] && 
      <Container maxWidth="md" style={{ padding: 10 }}>
        <Typography
          align="center"
          color="secondary"
          variant="h4"
        >
          {data.text[2]}
        </Typography>
      </Container>}
      {(data.countdown||{}).duration && <Container>
        <Countdown text={data.countdown.text} duration={data.countdown.duration} expiredText={data.countdown.expiredText}/>
        </Container>}
      {data.image && 
      <Container maxWidth="md" style={{ padding: 10, textAlign: "center" }}><img src={data.image} width='100%' onClick={onButtonClicked} alt='Reward'/></Container>}
      <Container maxWidth="md" style={{ padding: 30, textAlign: "center" }}>
        <Button
          variant="contained"
          focusRipple
          onClick={onButtonClicked}
          className={classes.button}
        >
          <Typography variant="h5" className={classes.text}>
            {data.button.text}
          </Typography>
        </Button>
      </Container>
    </React.Fragment>
  );
};
export default Complete;
