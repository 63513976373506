import 'whatwg-fetch';
import qs from "query-string";
import uuid from 'uuidv4';

//const trackUrl="https://7rm73pc4bg.execute-api.us-east-1.amazonaws.com/dev/event";
const trackUrl="https://3tt0xhv5u7.execute-api.us-east-1.amazonaws.com/prod/event";

if(!window.AppSessionId){
  window.AppSessionId=uuid();
}

const readCookie = (a) => {
    var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
};

const eventTemplate={
  event: 'leadgen',
  app: 'adulttime-survey',
  event_type: null,
  event_data:{} ,
  time: null,
  request: {
    session_id:'',
    ip: '##IP##',
    ua: '##UA##',
    referrer_url: '',
    current_url: ''
  },
  user: {
    'ga_user_id': null
  },
  tracking:{
    adv_id: null,
    campaign: null,
    gallery_id: null
  }
};


const args = qs.parse(window.location.search);
const adv_id=args.ad||args.adv_id||null;
const campaign=args.campaign||args.acampaign||null;
const gallery_id=args.gallery_id||null;


const sendChugEvent = async (eventType,data)=>{
  let payload=eventTemplate;
  
  payload['event_type']=eventType;
  payload['request']['session_id']=window.AppSessionId;
  payload['request']['current_url']=window.location.href;
  payload['request']['referrer_url']=document.referrer;
  payload['tracking']['adv_id']=adv_id;
  payload['tracking']['campaign']=campaign;
  payload['tracking']['gallery_id']=gallery_id;
  payload['user']['ga_user_id']=readCookie('_ga');
  payload['event_data']=data;
  
  //console.log(payload);
  return window.fetch(trackUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: JSON.stringify(payload)
    }).then(function(response) {
    }).catch(function(ex) {
    });
  
};

export default sendChugEvent;