import React from "react";

import { Grid, Container, Grow } from "@material-ui/core";
import RoundChoice from "./RoundChoice";

const RoundChoices = props => {
  let { data, onChoice } = props;
  return (
    <Grow
      in={true}
      style={{ transformOrigin: "50 50 50" }}
      {...(true ? { timeout: 2000 } : {})}
    >
      <Container maxWidth="md">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={4}>
              {data.choices.map((value, idx) => (
                <Grid key={idx} item xs={6} sm={4}>
                  <RoundChoice
                    image={value.image}
                    text={value.text}
                    onClick={onChoice(value.text)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grow>
  );
};
export default RoundChoices;
