import React from "react";
import { Query } from "react-contentful";

const GetSurveyData = props => {
  let { id, onLoaded } = props;
  return (
    <Query contentType="survey" query={{ "fields.id[in]": id }}>
      {({ data, error, fetched, loading }) => {
        if (loading || !fetched) {
          return null;
        }

        if (error) {
          console.error(error);
          return null;
        }

        if (!data || data.items.length < 1) {
          return <p>Page does not exist.</p>;
        }

        onLoaded(data.items[0]);
        // Process and pass in the loaded `data` necessary for your page or child components.
        return <div />;
      }}
    </Query>
  );
};

export default GetSurveyData;
