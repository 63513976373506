import React from "react";
import { Container } from "@material-ui/core";
import ProgressBar from "./ProgressBar";
import SurveySection from "./SurveySection";
import Complete from "./Complete";
import GetSurveyData from "./GetSurveyData";
import { Helmet } from "react-helmet";
import sendChugEvent from '../utils/sendChugEvent';

const Survey = props => {
  const [currentSection, setCurrentSection] = React.useState(0);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [surveyData, setSurveyData] = React.useState(null);
  const [surveyTitle, setSurveyTitle] = React.useState(null);

  const surveyId = props.match.params.survey_id || 'quiz1';

  React.useEffect(() => {
    sendChugEvent('surveyLoaded', { 'survey_id': surveyId });
  }, [surveyId]);


  const onDataLoaded = data => {
    setDataLoaded(true);
    setSurveyTitle(data.fields.pageTitle);
    setSurveyData(data.fields.config);
  };

  const logChoice = (choice) => {
    let sv = surveyData[currentSection];
    sendChugEvent('surveyChoice', {
      'survey_id': surveyId,
      'type': sv['type'],
      'question': sv['question'],
      'choice': choice
    });
  };

  const onSurveyChoice = choice => event => {
    if (currentSection < surveyData.length - 1) {
      logChoice(choice);
      setCurrentSection(currentSection + 1);
    }
    //setCurrentSection(9);
  };

  if (!dataLoaded)
    return <GetSurveyData id={surveyId} onLoaded={onDataLoaded} />;

  if (surveyData[currentSection].type === "completed")
    return <Complete surveyId={surveyId} data={surveyData[currentSection]} {...props}/>;
  else
    return (
      <React.Fragment>
        <Helmet>
          <title>{surveyTitle}</title>
        </Helmet>
       
        <Container maxWidth="sm">
          <ProgressBar value={currentSection} total={surveyData.length} />
        </Container>
        
        <SurveySection
          data={surveyData[currentSection]}
          onChoice={onSurveyChoice}
        />
      </React.Fragment>
    );
};

export default Survey;
