import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

const style = {
  root: {
    height: 20,
    borderRadius: 10
  },
  bar: {
    borderRadius: 10,
    height: 20
    //backgroundColor: "#ff6c5c"
  }
};

const StyledLinearProgress = withStyles(style)(LinearProgress);
const ProgressBar = props => {
  let { value, total } = props;
  let progress = ((value + 1) / (total + 1)) * 100;
  return (
    <StyledLinearProgress
      variant="determinate"
      value={progress}
      //className={classes.margin}
    />
  );
};

export default ProgressBar;
