import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    type: "dark",
    secondary: { main: "#63d796" }
  },
  shape: {
    borderRadius: 8
  },
  overrides: {
    MuiDrawer: {
      paper: {
        minWidth: 256
      },
      paperAnchorDockedLeft: {
        borderRight: "none"
      }
    }
  }
});

export default theme;
