import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  choice: {
    width: "calc(50% -1.5em)"
  },
  bigAvatar: {
    display: "inline-block",
    textAlign: "center",
    width: window.innerWidth > 600 ? 280 : 165,
    height: window.innerWidth > 600 ? 280 : 165,
    border: `6px solid ${theme.palette.secondary.light}`
  },
  text: {
    textAlign: "center"
  }
}));

export default function RoundChoice(props) {
  const classes = useStyles();
  let { image, text } = props;
  const [hovered, setHovered] = React.useState(false);
  let RoundChoiceStyle = {
    transform: hovered ? "scale(1.2,1.2)" : "scale(1,1)",
    transition: "0.3s"
  };
  return (
    <Grid
      container
      justify="center"
      {...props}
      onMouseOut={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
      style={RoundChoiceStyle}
      className={classes.choice}
    >
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Avatar
          alt="Remy Sharp"
          //src="https://pornhubsurvey.com/php/images/step-small-boobs.jpg?m=2"
          src={image}
          className={classes.bigAvatar}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.text}>
          {text}
        </Typography>
      </Grid>
    </Grid>
    //</Grow>
  );
}
