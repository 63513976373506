import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CountdownTimer from 'react-countdown-now';

const useStyles = makeStyles(theme => ({
  text: {
    color: 'yellow',
  },
  clock: {
    color: 'white',
    paddingLeft: 10
  }
}));


const Countdown = props => {
  let { text, duration, expiredText } = props;
  let classes = useStyles();

  const renderer = ({ minutes, seconds, completed }) => {
    let text;
    if (completed) {
      // Render a complete state
      text = expiredText;
    }
    else {
      // Render a countdown
      text = `${minutes}:${pad(seconds,2)}`;
    }
    return (<Typography variant="span"
          className={classes.clock}>{text}</Typography>);
  };

  return (
    <React.Fragment>
    <Typography
          align="center"
          color="yellow"
          variant="h4"
          className={classes.text}
        >
    {text} 
    
    <CountdownTimer
      date={Date.now() + duration*1000}
      renderer={renderer}
  />
  </Typography>
    </React.Fragment>
  );

};

export default Countdown;

function pad(num, size) {
  var s = "000000000" + num;
  return s.substr(s.length - size);
}
